import React, {FC, useContext, useEffect} from "react";
import {graphql} from "gatsby";
import Img from "gatsby-image";

import BackgroundImage from "gatsby-background-image";

// import material-ui and icons
import {FaCaretRight} from "react-icons/fa";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import cocktail from "../images/svg/cocktail-solid.svg";
import ginStill from "../images/svg/GinStillIcon.svg";
import CBDLogo from "../images/svg/logoBlue.svg";
import {FaPaperPlane} from "react-icons/fa";

// import components -----------------------------------------------
import PageLayout from "../components/layout/page-layout";
import IndexHeading from "../components/index-page/index-heading";
import {PageContext, pages} from "../context/page-context";
import Button from "../components/button/button";
import Instagram from "../components/instagram/instagram";

// hooks
import useSticky from "../hooks/use-sticky";

// SVG
import leftCloud from "../images/svg/LeftCloudBlue.svg";
import rightCloud from "../images/svg/RightCloudBlue.svg";

// import styles ====================================================

import style from "../styles/page-styles/index-page.module.scss";

const IndexPage: FC<IIndexPage> = ({data}: IIndexPage) => {
  const {isSticky, element} = useSticky();
  const desktop = useMediaQuery("(min-width:992px)");
  const ipad = useMediaQuery(
    "only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape)"
  );
  const {sanityTechnicalInfo: technical} = data;
  const pageContext = useContext(PageContext);
  useEffect(() => {
    pageContext.setCurrentPage(pages.home);
  }, [pageContext.currentPage]);
  return (
    <PageLayout sticky={isSticky}>
      {/* Start of actual content */}

      <section ref={element} className={style.landingSection}>
        <IndexHeading bottleImg={data.sanityLandingPage.quickBuyImg}/>
      </section>

      {/* Technical section below */}

      <section className={`${style.techSection}`}>
        <img className={style.leftCloud} src={leftCloud}/>
        <img src={rightCloud} className={style.rightCloud}/>
        <div className={style.techContainer}>
          <h3>Our Approach</h3>

          <div className={style.techContent}>
            <Img
              className={style.techImg}
              imgStyle={{objectPosition: "50% 10%"}}
              fluid={data.sanityLandingPage.heroImage.asset.fluid}
              alt={data.sanityLandingPage.heroImage.alt}
            />

            <div className={style.techGrid}>
              <div className={style.techCard}>
                <img src={CBDLogo} className={style.techIcon}/>
                <p>{technical.point1}</p>
              </div>
              <div className={style.techCard}>
                <img src={cocktail} className={style.techIcon}/>
                <p>{technical.point2}</p>
              </div>
              <div className={style.techCard}>
                <img src={ginStill} className={style.techIcon}/>
                <p>{technical.point3}</p>
              </div>
              <Button
                type="filled"
                linkTo="/technical"
                rightIcon={<FaCaretRight size="25px"/>}
                className={style.techBtn}
              >
                Learn More...
              </Button>
            </div>
          </div>
        </div>
      </section>

      <BackgroundImage
        Tag={`div`}
        style={{backgroundAttachment: ipad || !desktop ? "scroll" : "fixed"}}
        className={style.panoramaBG}
        fluid={data.MountainPanorama.childImageSharp.fluid}
      >
        <Instagram/>
        <div className={style.contactInfoOnImage}>
          <p>
            Want to join the journey?
            <Button type="filled" linkTo="/contact">
              Contact us
              <FaPaperPlane className={style.icon} size="1.6rem"/>
            </Button>
            {/* <MuiButton>Contact us</MuiButton> */}
          </p>
          {/* <Button type="filled" linkTo="/contact">
            Send A Message
            <FaPaperPlane className={style.icon} />
          </Button> */}
        </div>
      </BackgroundImage>


      {/* <Latest />
      <div className={style.contactBanner}>
        <img src={smallCloudFlat} className={style.contactSmallCloudLeft} />
        <img src={smallCloudFlat} className={style.contactSmallCloudRight} />
        <p>Need more information? Contact us now.</p>
        <Button type="filled" linkTo="/contact">
          Send A Message
        </Button>
      </div> */}
    </PageLayout>
  );
};

export default IndexPage;

interface IIndexPage {
  data: {
    MountainPanorama: {
      childImageSharp: {
        fluid: any;
      };
    };
    sanityTechnicalInfo: {
      point1: string;
      point2: string;
      point3: string;
    };
    sanityLandingPage: {
      quickBuyImg?: {
        asset: {
          fluid: any;
        };
      };
      heroImage: {
        alt: string;
        asset: {
          _id: string;
          fluid: any;
        };
      };
    };
  };
}

export const indexPageQuery = graphql`
  query indexPageProduct {
    MountainPanorama: file(relativePath: { eq: "mountainPanorama.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    sanityTechnicalInfo {
      point1
      point2
      point3
    }
    sanityLandingPage {
      quickBuyImg {
        asset {
          _id
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      heroImage {
        alt
        asset {
          _id
          fluid(maxWidth: 700) {
            ...GatsbySanityImageFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;
// LandscapeImg: file(relativePath: { eq: "Landscape_CloudGin.jpg" }) {
//   childImageSharp {
//     fluid(quality: 100, maxWidth: 1920) {
//       ...GatsbyImageSharpFluid_withWebp_noBase64
//     }
//   }
// }
