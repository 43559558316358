import React, { FC, useState } from "react";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { useMediaQuery } from "@material-ui/core";
// @ts-ignore
import * as style from "./instagram.module.scss";
import InstagramCard from "./instagram-card";
import { animated, useSpring, useTransition } from "react-spring";
import { FaRegHeart } from "react-icons/fa";
import { FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import IconButton from "@material-ui/core/IconButton";

interface IInstagramProps {}

interface IQuery {
  allInstaNode: {
    edges: [post];
  };
}

interface post {
  node: {
    caption: string;
    id: string;
    // likes: string;

    localFile: {
      childImageSharp: {
        fixed: any;
      };
    };
  };
}

const Instagram: FC<IInstagramProps> = ({}: IInstagramProps) => {
  const insta: IQuery = useStaticQuery(instagramQuery);
  const allPosts = insta.allInstaNode.edges;
  const [postIndex, setPostIndex] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [isRight, setRight] = useState<boolean>();
  const tablet = useMediaQuery("(min-width: 768px)");
  let cardWidth = 240;

  const transitions = useTransition(postIndex, (p) => p, {
    from: { opacity: 0, transform: `translate3d(${isRight ? "100%" : "-100%"},0,0)` },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: `translate3d(${isRight ? "-50%" : "50%"},0,0)` },
    initial: { opacity: 0, transform: "translate3d(0%,0,0)" },
  });

  const nextImage = (): void => {
    if (!tablet) {
      setRight(true);
      let newIndex = (postIndex + 1) % allPosts.length;
      setPostIndex(newIndex);
    }
    if (tablet && offset > 3 * cardWidth - cardWidth * allPosts.length) {
      setOffset(offset - cardWidth);
    }
  };
  const prevImage = (): void => {
    if (!tablet) {
      setRight(false);
      let newIndex = (postIndex - 1 + allPosts.length) % allPosts.length;
      setPostIndex(newIndex);
    }
    if (tablet && offset < 0) {
      setOffset(offset + cardWidth);
    }
  };

  return (
    <div className={style.instagram}>
      <h3>Latest from our Instagram</h3>
      <div className={style.container}>
        <IconButton className={style.btnPrev} onClick={prevImage}>
          <FiArrowLeftCircle size="3rem" />
        </IconButton>
        <div className={style.postContainer}>
          {tablet
            ? allPosts.map((item, index) => {
                let post = item.node;
                return (
                  <div
                    key={post.id}
                    style={{
                      transform: `translate3d(${offset + index * cardWidth}px, 0, 0)`,
                    }}
                    className={style.animatedCard}
                  >
                    <InstagramCard
                      key={post.id}
                      id={post.id}
                      img={post.localFile.childImageSharp.fixed}
                      caption={post.caption}
                      // likes={post.likes}
                    />
                  </div>
                );
              })
            : transitions.map(({ item, props, key }) => {
                const post = allPosts[item].node;
                return (
                  <animated.div key={post.id} style={props} className={style.animatedContainer}>
                    <InstagramCard
                      key={post.id}
                      id={post.id}
                      img={post.localFile.childImageSharp.fixed}
                      caption={post.caption}
                      // likes={post.likes}
                    />
                  </animated.div>
                );
              })}
          {!tablet && <div className={style.spacer} />}
          <div className={style.spacer} />
        </div>
        <IconButton className={style.btnNext} onClick={nextImage}>
          <FiArrowRightCircle size="3rem" />
        </IconButton>
      </div>
    </div>
  );
};

export default Instagram;

export const instagramQuery = graphql`
  query instaQuery {
    allInstaNode(limit: 10, sort: { fields: timestamp, order: DESC }) {
      edges {
        node {
          localFile {
            childImageSharp {
              fixed(width: 200, height: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          id
          caption
          timestamp
        }
      }
    }
  }
`;
