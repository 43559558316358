import React, { FC } from "react";
import Img from "gatsby-image";
import { FaInstagram } from "react-icons/fa";
// @ts-ignore
import * as style from "./instagram-card.module.scss";

interface IInstagramCardProps {
  img: any;
  caption: string;
  id: string;
  // likes: string;
}

const InstagramCard: FC<IInstagramCardProps> = ({
  // likes,
  img,
  id,
  caption,
}: IInstagramCardProps) => {
  return (
    <a href={`https://www.instagram.com/p/${id}`} className={style.card}>
      <Img className={style.img} fixed={img} />
      <div>
        <FaInstagram />
        <p>Cloud Gin</p>
        {/*<p className={style.likes}>{likes} likes</p>*/}
      </div>
      <p className={style.caption}>{caption}</p>
    </a>
  );
};

export default InstagramCard;
