import React, { FC, useState, useRef } from "react";
import Img from "gatsby-image";

import IconButton from "@material-ui/core/IconButton";
import { FaCaretRight } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ProductText from "../products/product-text/product-text";
import Button from "../button/button";

import style from "./index-heading.module.scss";

import smallCloud from "../../images/svg/SmallCloudBlue.svg";
import leftCloud from "../../images/svg/LeftCloudBlue.svg";
import rightCloud from "../../images/svg/RightCloudBlue.svg";
import cloudTitle from "../../images/svg/CloudTitle_svgFilters.svg";

// animation stuff ====================================================================
import { useSpring, useTransition, animated } from "react-spring";

interface IIndexHeading {
  bottleImg: any;
}
const IndexHeading: FC<IIndexHeading> = ({ bottleImg }: IIndexHeading) => {
  const [toggle, setToggle] = useState(false);
  const [bottleToggle, setBottleToggle] = useState(false);
  const tablet = useMediaQuery("(min-width: 768px)");
  const tabletWide = useMediaQuery("(min-width: 992px");
  const desktop = useMediaQuery("(min-width:1200px)");
  const desktopWide = useMediaQuery("(min-width:1440px)");
  const desktopXL = useMediaQuery("(min-width:1700px)");

  // controls the bottle following the mouse
  const [parallax, setParallax] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));
  const calc = (x: number, y: number) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
  const transParallax = (x: number, y: number) =>
    `translate3d(${x / 22}px, ${y / 20}px,0) rotateZ(-20deg) scale(0.7)`;

  // below controls the bottle arcing into the middle
  const bottleArc = (x: number, y: number, r: number, s: number) => {
    if (tablet && !desktop) {
    } else if (desktop && !desktopWide) {
      y = 0.011 * (x + 100) * x;
    } else if (desktopWide && !desktopXL) {
      // let w = (window.innerWidth - ) - (window.innerWidth)
      y = 0.011 * (x + 135) * x;
    } else if (desktopXL) {
      y = 0.005 * (x + 230) * x;
    } else {
      y = 0.011 * (x + 160) * x;
    }
    return `translate3d(${x}%, ${y}%, 0) rotateZ(${r}deg) scale(${s})`;
  };

  const [bottleSpringProps, setBottleSpring] = useSpring(() => ({
    xyzs: [17, 0, -20, 0.7],
    config: { mass: 7.5, tension: 100, friction: 35, precision: 0.06, velocity: 0 },
  }));

  // "transitions" controls the heading and quick buy moving in and out
  const transitions = useTransition(toggle, null, {
    from: {
      transform: toggle ? "translateX(100%)" : "translateX(-100%)",
      opacity: 0,
    },
    enter: {
      transform: "translateX(0)",
      opacity: 1,
    },
    leave: {
      transform: toggle ? "translateX(-100%)" : "translateX(100%)",
      opacity: 0,
    },
    initial: null,
  });

  const handleBuyNow = () => {
    // makes the parallax effect start 2.5 secs after the end of the arc animation
    if (toggle) {
      setTimeout(() => setBottleToggle(false), 2500);
    } else if (toggle == bottleToggle) {
      setBottleToggle(true);
    } else {
      return;
    }
    let xStart = 0;
    let xEnd = -160;
    let yEnd = -100;
    if (tablet && !tabletWide) {
      xEnd = -10;
      yEnd = -80;
    } else if (tabletWide && !desktop) {
      xEnd = -10;
    } else if (desktop && !desktopWide) {
      xEnd = -100;
    } else if (desktopWide && !desktopXL) {
      xEnd = -135;
    } else if (desktopXL) {
      xEnd = -210;
    }
    setBottleSpring({ xyzs: toggle ? [xStart, 0, -20, 0.7] : [xEnd, yEnd, 0, 1] });
    setToggle(!toggle);
  };
  return (
    <div
      className={`${style.indexHeading}`}
      onMouseMove={({ clientX: x, clientY: y }) => setParallax({ xy: calc(x, y) })}
    >
      {transitions.map(({ item, key, props }) =>
        item ? (
          <animated.div key={key} style={props} className={style.productContainer}>
            <IconButton className={style.closeIcon} onClick={handleBuyNow}>
              <AiFillCloseCircle />
            </IconButton>
            <ProductText homepage />
          </animated.div>
        ) : (
          <animated.div key={key} style={props} className={`${style.titleContainer}`}>
            <img src={cloudTitle} className={style.pageTitle} alt="Cloud Logo Text" />
            <h3>Mastercrafted</h3>
            <h2>CBD Gin</h2>
            <div className={style.btnGroup}>
              <Button className={style.moreBtn} type="outline" linkTo="/technical">
                Discover More
              </Button>
              <Button
                className={style.buyNowBtn}
                type="filled"
                rightIcon={<FaCaretRight size="30px" />}
                onClick={handleBuyNow}
              >
                Buy Now
              </Button>
            </div>
          </animated.div>
        )
      )}
      <animated.div
        className={style.cutOutBottle}
        onClick={handleBuyNow}
        style={{
          transform: bottleToggle
            ? // @ts-ignore
              bottleSpringProps.xyzs.interpolate(bottleArc)
            : //@ts-ignore
              parallax.xy.interpolate(transParallax),
        }}
      >
        <Img fluid={bottleImg.asset.fluid} />
      </animated.div>
      <img
        src={leftCloud}
        className={`${style.leftCloud} ${toggle && style.leftCloud__displayNone}`}
      />
      <img alt="small background cloud svg" src={smallCloud} className={style.smallCloud} />
      <img
        alt="background cloud on the right of the screen"
        src={rightCloud}
        className={style.rightCloud}
      />
    </div>
  );
};

export default IndexHeading;
